import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import { css } from 'emotion'
import { array, arrayOf, func, object, shape } from 'prop-types'
import { DragDropContext } from 'react-beautiful-dnd'
import { Column, Flex, Row } from 'src/microcomponents/flexbox-helpers'
import window from 'global/window'

import Button, { HYBRID } from 'components/button'

import Screen from 'components/screen/container'
import BackButton from 'components/back-button'

import List from 'components/draggable/list'
import UploadFile from 'microcomponents/upload-file'
import Switch from 'src/microcomponents/switch'

import {
  centerContainer,
  content,
  descriptionClass,
  headerImage as headerImageClass,
  infoContainer,
  item as itemClass,
  leftBar,
  main,
  overflowContainer,
  reveal,
  title,
  topBrandInfo,
  uploadBttn
} from '../edit-page-styles.js'

export class EditBrand extends PureComponent {
  static propTypes = {
    brandItem: object,
    createOrUpdateBrand: func,
    items: shape({
      brandItems: arrayOf(object),
      products: array
    }),
    resetBrandItem: func,
    updateBrandObject: func,
    uploadPublicFile: func
  };

  state = {
    about: '',
    header_image: '',
    logo: '',
    name: '',
    slug: '',
    website: '',
    is_ecommerce: false
  };

  componentDidMount () {
    window.onpopstate = this.props.resetBrandItem
  }

  componentDidUpdate (prevProps) {
    this.brandItemLoadedEffect(prevProps)
  }

  brandItemLoadedEffect (prevProps) {
    const { brandItem } = this.props

    if (!brandItem.id) return
    if (brandItem !== prevProps.brandItem) {
      const {
        id,
        about,
        // eslint-disable-next-line camelcase
        header_image,
        logo,
        name,
        slug,
        website,
        // eslint-disable-next-line camelcase
        is_ecommerce
      } = brandItem

      this.setState({
        id,
        about,
        header_image,
        logo,
        name,
        slug,
        website,
        is_ecommerce
      })
    }
  }

  handleCreateOrUpdateBrand = () => {
    this.props.updateBrandObject(this.state)
    this.props.createOrUpdateBrand()
  };

  render () {
    const { items, uploadPublicFile } = this.props
    const {
      about,
      header_image: headerImage,
      is_ecommerce: isEcommerce,
      logo,
      name,
      slug,
      website
    } = this.state
    return (
      <Screen>
        <Row full className={main}>
          <Column className={leftBar} componentStyle={{ position: 'absolute' }}>
            <BackButton path="/catalog/brands" />
          </Column>
          <DragDropContext>
            <Row full className={reveal}>
              <Flex full top={1}>
                <Column
                  className={centerContainer}
                  componentStyle={{ margin: '0 auto' }}
                >
                  <Row align="center" justify="space-between">
                    <Switch
                      customLabel={'E-Commerce'}
                      enabled={isEcommerce}
                      onChange={(checked) => {
                        this.setState({ is_ecommerce: checked })
                      }}
                    />
                    <Flex>
                      <div className={uploadBttn}>
                        <UploadFile
                          postUpload={({ downloadUrl }) =>
                            this.setState({ header_image: downloadUrl })
                          }
                          uploadFile={uploadPublicFile}
                        >
                          Upload Header
                        </UploadFile>
                      </div>
                      <div className={uploadBttn}>
                        <UploadFile
                          postUpload={({ downloadUrl }) =>
                            this.setState({ logo: downloadUrl })
                          }
                          uploadFile={uploadPublicFile}
                        >
                          Upload Logo
                        </UploadFile>
                      </div>
                      <Button
                        onClick={this.handleCreateOrUpdateBrand}
                        type={HYBRID}
                      >
                        Save Brand
                      </Button>
                    </Flex>
                  </Row>

                  <Column className={content} top={1} full>
                    <div className={topBrandInfo}>
                      <Column className={infoContainer}>
                        <div
                          className={headerImageClass}
                          style={{ backgroundImage: `url(${headerImage})` }}
                        />
                        <Column
                          componentStyle={{
                            zIndex: 10,
                            padding: '1rem'
                          }}
                        >
                          <Row full>
                            <input
                              className={title}
                              onBlur={(event) =>
                                this.setState({ name: event.target.value })
                              }
                              defaultValue={name}
                              placeholder="Brand name"
                            />

                            <StyledImage url={logo} />
                          </Row>

                          <textarea
                            className={descriptionClass}
                            onBlur={(event) =>
                              this.setState({ about: event.target.value })
                            }
                            defaultValue={about}
                            placeholder="DescriptionClass"
                          />

                          <Row>
                            <Flex full>
                              <input
                                className={descriptionClass}
                                onBlur={(event) =>
                                  this.setState({ website: event.target.value })
                                }
                                defaultValue={website}
                                placeholder="Website"
                              />
                            </Flex>

                            <Flex align="center" justify="center" full>
                              <label htmlFor="slug">www.eaze.com/brands/</label>
                              <input
                                id="slug"
                                className={descriptionClass}
                                onBlur={(event) =>
                                  this.setState({ slug: event.target.value })
                                }
                                defaultValue={slug}
                                style={{ paddingLeft: 0 }}
                                placeholder="brand-slug"
                              />
                            </Flex>
                          </Row>
                        </Column>
                      </Column>
                    </div>

                    <div className={overflowContainer}>
                      <List
                        axis="y"
                        className={GroupList}
                        distance={5}
                        emptyListText="Products that are associated with BRAND will show up here."
                        itemClassName={itemClass}
                        items={items.brandItems}
                        listName="brandItems"
                        narrow
                        shouldLinkOutItems
                      />
                    </div>
                  </Column>
                </Column>
              </Flex>
            </Row>
          </DragDropContext>
        </Row>
      </Screen>
    )
  }
}

// magic number: 30px here is twice the padding height (top and bottom)
const GroupList = css`
  padding: 0 15px;
  height: calc(100% - 30px);
`

const StyledImage = styled.img`
  height: 4rem;
  border-radius: 0.4rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.5);
`

export default EditBrand
